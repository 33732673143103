import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import DialogBox from "../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import { Box } from "@mui/system";
import { ThemeProvider } from "@mui/material/styles";
import {
  getMuiTheme,
  selectStyles,
  StripedDataGrid,
  textBoxStyles,
} from "../Visits/ConfigureNavigations";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { FaPencilAlt } from "react-icons/fa";
import {
  addNewCalculationService,
  getCalculationDataService,
} from "./Calculations";
import { toast } from "react-toastify";
import { requestFailed } from "../Visits/CreateVisit";

const Calculations = () => {
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);
  const [aggregateDropdowns, setAggregateDropdowns] = useState([]);
  const [calculationData, setCalculationData] = useState([]);

  const createNavigationSchema = yup.object().shape({
    newCalculation: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    aggregate: yup.string().required("This field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createNavigationSchema),
  });

  const notify = () =>
    toast.success("Calculation Added Successfully", {
      toastId: "form-creation",
    });

  useEffect(() => {
    getCalculationData();
  }, []);

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "name",
      // hide: true,
      headerName: "Name",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Name"}</div>,
      renderCell: (params) => {
        const name = params.row.name;

        return (
          <div className="grid-body">
            <p>{name}</p>
          </div>
        );
      },
    },
    {
      field: "notes",
      // hide: true,
      headerName: "Description",
      width: 290,
      renderHeader: () => <div className="grid-heading">{"Description"}</div>,
      renderCell: (params) => {
        const description = params.row.notes;

        return (
          <div className="grid-body">
            <p>{description}</p>
          </div>
        );
      },
    },
    {
      field: "aggregatorType",
      // hide: true,
      headerName: "Aggregate",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Aggregate"}</div>,
      renderCell: (params) => {
        const aggregate = params.row.aggregatorType;

        return (
          <div className="grid-body">
            <p>{aggregate?.name}</p>
          </div>
        );
      },
    },
    {
      field: "edit",
      // hide: true,
      headerName: "Edit",
      renderHeader: () => <div className="grid-heading">{"Edit"}</div>,
      width: 120,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              //   setDeleteId(id);
              //   setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaPencilAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const getCalculationData = async () => {
    setLoading(true);
    try {
      const res = await getCalculationDataService();
      if (res?.status) {
        setLoading(false);
        setCalculationData(res?.data?.result?.calculationData);
        setAggregateDropdowns(res?.data?.result?.aggregatorTypes);
      }
    } catch (err) {
      setLoading(false);
      console.log("Error: ", err?.message);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      const payload = {
        newCalculationName: data?.newCalculation,
        newCalculationNotes: data?.description,
        newCalculationAggregatorTypeId: data?.aggregate,
      };
      const res = await addNewCalculationService(payload);
      if (res?.status) {
        setLoading(false);
        notify();
        reset();
        getCalculationData();
      }
    } catch (err) {
      setLoading(false);
      requestFailed(err?.message);
      reset();
      console.log("Error: ", err?.message);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/countries">Calculations</Link>-{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={12}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Calculations</p>
              </div>
            </Col>
          </Row>
          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Row>
            <Col md={8} sm={12}>
              <Box sx={{ height: 400, width: "100%", display: "flex" }}>
                <ThemeProvider theme={getMuiTheme}>
                  <StripedDataGrid
                    className="allQuestionnaire-grid"
                    rows={calculationData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    // components={{ Toolbar: GridToolbar }}
                    pagination
                    rowHeight={38}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? "even"
                        : "odd"
                    }
                  />
                </ThemeProvider>
              </Box>
            </Col>
            <Col md={4} sm={12}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                sx={{
                  border: "1px solid rgb(224 224 224)",
                  borderRadius: "5px",
                  padding: "20px",
                  minheight: "500px",
                  maxHeight: "550px",
                }}
              >
                <h4>Add New Caclculation</h4>
                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    New Calculation
                    <span className="error-highlight">*</span>
                  </h5>
                  <FormControl sx={selectStyles}>
                    <TextField
                      type={"text"}
                      inputProps={{
                        style: textBoxStyles,
                      }}
                      {...register("newCalculation", {
                        onChange: (e) => {
                          setShowDialog(true);
                        },
                      })}
                    />
                  </FormControl>
                </div>
                {errors.newCalculation && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.newCalculation.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    Description
                    <span className="error-highlight">*</span>
                  </h5>
                  <FormControl sx={selectStyles}>
                    <TextField
                      type={"text"}
                      rows={2}
                      multiline
                      inputProps={{
                        style: textBoxStyles,
                      }}
                      {...register("description", {
                        onChange: (e) => {
                          setShowDialog(true);
                        },
                      })}
                    />
                  </FormControl>
                </div>
                {errors.description && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.description.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div style={{ marginTop: "30px" }}>
                  <h5 style={{ fontWeight: "bold" }}>
                    Type of aggregate
                    <span className="error-highlight">*</span>
                  </h5>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="aggregate"
                      defaultValue={""}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("aggregate", {
                        onChange: (e) => {
                          setValue("aggregate", e.target.value, {
                            shouldValidate: true,
                          });
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {aggregateDropdowns &&
                        aggregateDropdowns?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.aggregate && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.aggregate.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "30px", textAlign: "center" }}>
                  <button
                    type="submit"
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#337ab7",
                      padding: "8px 17px",
                      fontSize: "15px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Add New Calculation
                  </button>
                </div>
              </Box>
            </Col>
          </Row>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              {/* <Link to="/study-management/visits"> */}
              <div className="study-management-head-end">
                <button
                  className="study-management-create-btn-lg"
                  onClick={() => {
                    navigate("/study-management");
                  }}
                >
                  Back
                </button>
              </div>
              {/* </Link> */}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Calculations;
