import { Box, ThemeProvider } from "@mui/system";
import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { getMuiTheme } from "../Visits";
import { StripedDataGrid } from "../../Dispensation/Dispensations";
import DeleteModal from "../../../../../components/DeleteModal";
import { removeStudyRole } from "../../../../../services/visits";
import { toast } from "react-toastify";

const QuestionnaireTableComp = ({
  data,
  heading,
  id,
  getData,
  setLoad,
  rowName,
  keyName,
  apiName,
  isCalculation,
}) => {
  const [pageSize, setPageSize] = useState(5);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const notifyDelete = (msg) =>
    toast.success(msg, {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(msg, {
      // theme: "colored",
      toastId: "requestFailed",
    });
  const rows =
    data &&
    data.map((row) => ({
      id: row.id,
      name: rowName ? row?.[rowName][keyName] : "",
      delete: isCalculation ? row?.calculationId : row.id,
    }));
  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: heading,
      // hide: true,
      headerName: heading,
      width: 450,
      renderHeader: () => <div className="grid-heading">{heading}</div>,
      renderCell: (params) => {
        const name = params.row?.name;
        return (
          <div className="grid-body">
            <p>{name}</p>
          </div>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 100,
      renderCell: (params) => {
        const id = params.row.delete;

        return (
          <button
            onClick={() => {
              setShowConfirmModal(true);
              setDeleteId(id);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await apiName(deleteId);

      if (res.status) {
        setLoad(false);
        getData(id);
        setShowConfirmModal(false);
        notifyDelete(`${heading} Deleted Successfully`);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  const handleDeleteFun = () => {
    if (isCalculation) {
      apiName(deleteId);
    } else {
      handleDeleteConfirm();
    }
  };

  return (
    <>
      <Box sx={{ height: 240, width: "100%", display: "flex" }}>
        <ThemeProvider theme={getMuiTheme}>
          <StripedDataGrid
            className="allQuestionnaire-grid"
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            rowHeight={38}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </ThemeProvider>
      </Box>
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteFun}
          subject={heading}
        />
      )}
    </>
  );
};

export default QuestionnaireTableComp;
