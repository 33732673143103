import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  Chip,
} from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import DialogBox from "../../../../components/DialogBox";
import moment from "moment";
import {
  getVisitById,
  editVisit,
  getAllBusinessRules,
  getAllResonsService,
} from "../../../../services/visits";
import "../StudySetup.css";
import DynamicNavigtion from "./DynamicNavigation";

const EditVisit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const id = location.state.id;

  const [load, setLoad] = React.useState(false);

  const [businessRulesData, setBusinessRulesData] = React.useState([]);
  const [allReasons, setAllReasons] = useState([]);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [windowOverRideState, setWindowOverRideState] = React.useState(false);
  const [isScheduledState, setIsScheduledState] = React.useState(false);
  const [interviewModeState, setInterviewModeState] = React.useState(false);
  const [closeOutFormState, setCloseOutFormState] = React.useState(false);
  const [alwaysAvailableState, setAlwaysAvailableState] = React.useState(false);
  const [dosageModuleState, setDosageModuleState] = React.useState(false);
  const [visitBusinessRuleId, setVisitBusinessRuleId] = React.useState("");
  const [lastUpateState, setLastUpdateState] = React.useState("");

  const [dispensationState, setDispensationState] = React.useState([]);
  const [visitAvailableState, setVisitAvailableState] = React.useState(null);
  const [patientVisitsState, setPatientVisitsState] = React.useState([]);
  const [overrideReasonState, setOverrideReasonState] = useState("");

  const [interviewAvailableState, setInterviewAvailableState] =
    React.useState(false);
  // const [closeoutFormState, setCloseoutFormState] = React.useState(false);
  const [circularvisitState, setCircularvisitState] = React.useState(false);
  const [businessRuleState, setBusinessRuleState] = useState(null);

  const notify = () =>
    toast.success("Visit Edited Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      // theme: "colored",
      toastId: "requestFailed",
    });

  // .default("2023-02-15T09:43:40.377Z")

  const editVisitSchema = yup.object().shape({
    name: yup.string().required("This field is required"),
    daysExpected: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    windowBefore: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    windowAfter: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    notes: yup.string().required("This field is required"),
    lastUpdate: yup.string().required("This field is required"),
    windowOverride: yup.bool().oneOf([true, false]),
    isScheduled: yup.bool().oneOf([true, false]),
    interviewMode: yup.bool().oneOf([true, false]),
    closeOut: yup.bool().oneOf([true, false]),
    alwaysAvailable: yup.bool().oneOf([true, false]),
    overrideReason: yup.string().required("This field is required"),
    reasons: yup
      .array()
      .min(1, "Select at least one reason") // Ensure at least one selection
      .required("Reasons is required"),
    visitOrder: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    visitAnchor: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    circularVisitRepeatCount: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    visitStopHsn: yup.string().required("This field is required"),
    dosageModule: yup.bool().oneOf([true, false]),
    interviewModeAvailable: yup.bool().oneOf([true, false]),
    closeoutFormAvailable: yup.bool().oneOf([true, false]),
    circularVisit: yup.bool().oneOf([true, false]),
    dosageModuleEnabled: yup.bool().oneOf([true, false]),
    visitAvailableBusinessRuleId: yup
      .string()
      .required("This field is required"),
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(editVisitSchema),
  });

  const fetchVisitById = async (id) => {
    setLoad(true);
    try {
      const res = await getVisitById(id);
      const {
        name,
        daysExpected,
        windowBefore,
        windowAfter,
        notes,
        lastUpdate,
        windowOverride,
        isScheduled,
        interviewMode,
        closeOut,
        alwaysAvailable,
        reasons,
        visitOrder,
        visitAnchor,
        visitStopHsn,
        dosageModuleEnabled,
        dispensations,
        visitAvailableBusinessRule,
        visitAvailableBusinessRuleId,
        patientVisits,
        circularVisit,
        circularVisitRepeatCount,
        closeoutFormAvailable,
        interviewModeAvailable,
        overrideReason,
        visitAvailableBusinessRules,
      } = res?.data?.result?.editVisitViewModel;
      setLoad(false);
      setWindowOverRideState(windowOverride);
      setIsScheduledState(isScheduled);
      setInterviewModeState(interviewModeAvailable);
      setCloseOutFormState(closeoutFormAvailable);
      setAlwaysAvailableState(alwaysAvailable);
      setDosageModuleState(dosageModuleEnabled);
      setDispensationState(dispensations);
      setVisitAvailableState(visitAvailableBusinessRule);
      setPatientVisitsState(patientVisits);
      setVisitBusinessRuleId(visitAvailableBusinessRuleId);
      setLastUpdateState(lastUpdate);
      setCircularvisitState(circularVisit);
      // setCloseoutFormState(closeoutFormAvailable);
      setInterviewAvailableState(interviewModeAvailable);
      setOverrideReasonState(overrideReason);
      setBusinessRuleState(visitAvailableBusinessRules);

      reset({
        name,
        daysExpected,
        windowBefore,
        windowAfter,
        notes: notes ? notes : "",
        lastUpdate: lastUpdate
          ? moment(lastUpdate).format("YYYY-MM-DDTHH:mm")
          : "",
        windowOverride,
        isScheduled,
        interviewMode,
        closeOut,
        alwaysAvailable,
        reasons,
        visitOrder,
        visitAnchor,
        visitStopHsn,
        dosageModuleEnabled,
        circularVisitRepeatCount,
        overrideReason,
      });
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  React.useEffect(() => {
    if (id) fetchVisitById(id);
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setShowDialog(false);
      setLoad(true);

      const {
        name,
        daysExpected,
        windowBefore,
        windowAfter,
        lastUpdate,
        notes,
        windowOverride,
        isScheduled,
        interviewMode,
        closeOut,
        alwaysAvailable,
        reasons,
        visitOrder,
        visitAnchor,
        visitStopHsn,
        lastUpateState,
        dosageModule,
        circularVisitRepeatCount,
      } = data;

      const newData = {
        name,
        daysExpected,
        windowBefore,
        windowAfter,
        notes,
        lastUpdate,
        windowOverride,
        isScheduled,
        interviewModeAvailable: interviewModeState,
        closeoutFormAvailable: closeOutFormState,
        alwaysAvailable,
        reasons,
        visitOrder,
        visitAnchor,
        visitStopHsn,
        circularVisit: circularvisitState,
        circularVisitRepeatCount,
        dosageModuleEnabled: dosageModule,
        visitAvailableBusinessRuleId: visitBusinessRuleId,
        circularVisitRepeatCount,
        visitAvailableState,
        dispensationState,
        patientVisitsState,
        overrideReason: overrideReasonState,
        visitAvailableBusinessRules: businessRuleState,
      };

      const res = await editVisit(id, newData);
      if (res.status) {
        setLoad(false);
        notify();
        navigate("/study-management/visits");
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const fetchBusinessData = async () => {
    try {
      const res = await getAllBusinessRules();
      if (res.status) {
        setBusinessRulesData(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  const fetchAllReasons = async () => {
    try {
      const res = await getAllResonsService();
      if (res.status) {
        setAllReasons(res?.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  React.useEffect(() => {
    fetchBusinessData();
    fetchAllReasons();
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    if (isDirty) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/visits");
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/visits");
    }
    // navigate("/study-management/visits");
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "20px",
  };

  const textBoxBody = {
    marginLeft: "10px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  const texAreaStyles = {
    fontSize: 15,
    width: "500px",
    height: "40px",
  };

  const selectStyles = {
    width: 530,
    marginTop: 1,
  };

  const rowSpacing = {
    marginTop: "2%",
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link> |{" "}
            <p className="study-edit-link">Edit Visit</p>-{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ height: "auto", width: "100%" }}
            autoComplete="off"
          >
            <div className="createVisitBody">
              <div className="createVisitHeader">
                <h1>Edit Visit</h1>
              </div>
              <div className="createVisitFormBody">
                <div className="createVisitInput">
                  <p style={labelStyles}>
                    Name {/* {errors.name?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <TextField
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("name", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.name && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.name.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p style={labelStyles}>
                    Days Expected
                    <span className="error-highlight">*</span>
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("daysExpected", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.daysExpected && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.daysExpected.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p>
                    Window Before {/* {errors.windowBefore?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("windowBefore", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.windowBefore && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.windowBefore.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p>
                    Window After {/* {errors.windowAfter?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("windowAfter", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.windowAfter && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.windowAfter.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p>
                    Circular Visit Repeat Count
                    <span className="error-highlight">*</span>
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("circularVisitRepeatCount", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.circularVisitRepeatCount && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.circularVisitRepeatCount.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p>
                    Notes
                    <span className="error-highlight">*</span>
                  </p>
                  <TextField
                    multiline
                    rows={1}
                    inputProps={{
                      style: texAreaStyles,
                    }}
                    {...register("notes", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.notes && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.notes.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p>
                    Last Update
                    <span className="error-highlight">*</span>
                  </p>
                  <TextField
                    value={lastUpateState}
                    style={textBoxBody}
                    type="datetime-local"
                    inputProps={{
                      style: textBoxStyles,
                    }}
                    {...register("lastUpdate", {
                      onChange: (e) => {
                        setShowDialog(true);
                        setLastUpdateState(e.target.value);
                      },
                    })}
                  />
                </div>
                {errors.lastUpdate && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.lastUpdate.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitCheckbox">
                  <p>
                    Window Override {/* {errors.windowOverride?.message ? ( */}
                    {/* <span className="error-highlight">*</span> */}
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <Checkbox
                    checked={windowOverRideState}
                    style={{ fontSize: "15px" }}
                    // checked={checked}
                    // onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("windowOverride", {
                      onChange: (e) => {
                        setShowDialog(true);
                        setWindowOverRideState(!windowOverRideState);
                      },
                    })}
                  />
                </div>
                {errors.windowOverride && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.windowOverride.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitInput">
                  <p>
                    Override Reason
                    <span className="error-highlight">*</span>
                  </p>
                  <TextField
                    type={"text"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("overrideReason", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>
                {errors.overrideReason && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.overrideReason.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className="createVisitCheckbox">
                  <p>
                    Is Scheduled {/* {errors.isScheduled?.message ? ( */}
                    {/* <span className="error-highlight">*</span> */}
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <Checkbox
                    checked={isScheduledState}
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("isScheduled", {
                      onChange: (e) => {
                        setShowDialog(true);
                        setIsScheduledState(!isScheduledState);
                      },
                    })}
                  />
                </div>

                {errors.isScheduled && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.isScheduled.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitCheckbox">
                  <p>
                    Interview Mode Available{" "}
                    {/* {errors.interviewMode?.message ? ( */}
                    {/* <span className="error-highlight">*</span> */}
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <Checkbox
                    checked={interviewModeState}
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("interviewMode", {
                      onChange: (e) => {
                        setInterviewModeState(!interviewModeState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.interviewMode && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.interviewMode.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitCheckbox">
                  <p>
                    Closeout Form Available{" "}
                    {/* {errors.closeOut?.message ? ( */}
                    {/* <span className="error-highlight">*</span> */}
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <Checkbox
                    checked={closeOutFormState}
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("closeOut", {
                      onChange: (e) => {
                        setCloseOutFormState(!closeOutFormState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.closeOut && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.closeOut.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitCheckbox">
                  <p>
                    Always Available{" "}
                    {/* {errors.alwaysAvailable?.message ? ( */}
                    {/* <span className="error-highlight">*</span> */}
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <Checkbox
                    checked={alwaysAvailableState}
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("alwaysAvailable", {
                      onChange: (e) => {
                        setAlwaysAvailableState(!alwaysAvailableState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.alwaysAvailable && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.alwaysAvailable.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p>
                    Reasons {/* {errors.reasons?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      labelId="reasons-label"
                      name="reasons"
                      multiple
                      value={getValues("reasons") || []} // Use getValues to get the current selected values
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Reasons",
                      }}
                      onChange={(e) => {
                        setValue("reasons", e.target.value, {
                          shouldValidate: true,
                        });
                        setShowDialog(true);
                      }}
                      // input={<OutlinedInput label="Reasons" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {" "}
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                allReasons?.find(
                                  (item) => item?.value === value
                                )?.title
                              }
                              sx={{ fontSize: "12px" }}
                            />
                          ))}
                        </Box>
                      )}
                      // MenuProps={MenuProps}
                    >
                      {allReasons?.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {errors.reasons && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.reasons.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p>
                    Visit Order {/* {errors.visitOrder?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("visitOrder", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.visitOrder && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.visitOrder.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p>
                    Visit Anchor {/* {errors.visitAnchor?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <TextField
                    type={"number"}
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      min: 0,
                    }}
                    {...register("visitAnchor", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.visitAnchor && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.visitAnchor.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p>
                    Visitstop_HSN {/* {errors.visitStopHsn?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <TextField
                    style={textBoxBody}
                    inputProps={{
                      style: textBoxStyles,
                      maxLength: 1,
                    }}
                    {...register("visitStopHsn", {
                      onChange: (e) => {
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.visitStopHsn && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.visitStopHsn.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitCheckbox">
                  <p>Dosage Module Enabled</p>
                  <Checkbox
                    checked={dosageModuleState}
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("dosageModule", {
                      onChange: (e) => {
                        setDosageModuleState(!dosageModuleState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.dosageModule && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitInput">
                        <p
                          style={{
                            marginTop: "10px",
                            color: "blue",
                          }}
                        >
                          {errors.dosageModule.message}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitCheckbox">
                  <p>Circular Visit</p>
                  <Checkbox
                    checked={circularvisitState}
                    style={{ fontSize: "15px" }}
                    inputProps={{ "aria-label": "controlled" }}
                    {...register("circularVisit", {
                      onChange: (e) => {
                        setCircularvisitState(!circularvisitState);
                        setShowDialog(true);
                      },
                    })}
                  />
                </div>

                {errors.circularVisit && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitInput">
                        <p
                          style={{
                            marginTop: "10px",
                            color: "blue",
                          }}
                        >
                          {errors.circularVisit.message}
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p style={labelStyles}>
                    Visit Available Business Rule{" "}
                    {/* {errors.name?.message ? ( */}
                    <span className="error-highlight">*</span>
                    {/* ) : (
                      <></>
                    )} */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      value={visitBusinessRuleId}
                      name="visitAvailableBusinessRuleId"
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...register("visitAvailableBusinessRuleId", {
                        onChange: (e) => {
                          setValue(
                            "visitAvailableBusinessRuleId",
                            e.target.value,
                            {
                              shouldValidate: true,
                            }
                          );
                          setVisitBusinessRuleId(e.target.value);
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {businessRulesData.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {errors.visitAvailableBusinessRuleId && (
                  <>
                    <div className="createVisitFlexEnd">
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errors.visitAvailableBusinessRuleId.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="createVisitInput">
                  <p>Configure Navigations</p>
                  <div style={{ width: "100vw", marginLeft: "290px" }}>
                    <button
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        color: "white",
                        backgroundColor: "#337ab7",
                        padding: "8px",
                        fontSize: "12px",
                        border: "none",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        navigate(
                          "/study-management/visits/configure-navigation",
                          {
                            state: {
                              id: id,
                            },
                          }
                        );
                      }}
                    >
                      Configure Navigations
                    </button>
                  </div>
                  {/* <Link className="navigationLink">Add Visit Navigation</Link> */}
                </div>

                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      {/* <Link to="/study-management-builder"> */}
                      <button
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                        className="study-management-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      {/* <Link to="/study-management-builder"> */}
                      <button
                        type="submit"
                        className="study-management-create-btn-md"
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Box>
        </div>
      )}
    </>
  );
};

export default EditVisit;
