import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import DialogBox from "../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../hooks/useCallbackPrompt";
import { Box, FormControl, MenuItem, Select, TextField } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createNewDispensationDetailService,
  getAllDrugLevelService,
  getAllDrugTypesService,
} from "../../../../services/dispensations";
import { toast } from "react-toastify";

const CreateDispensstionDetails = () => {
  const location = useLocation();

  const dispensationId = location.state?.id;
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const createDispensationSchema = yup.object().shape({
    drugTypeId: yup.string().required("This field is required"),
    dosageLevelId: yup.string().required("This field is required"),
    quantity: yup
      .number()
      .typeError("Quantity must be a number")
      .required("This field is required")
      .min(0, "Quantity cannot be less than 0"),
    doNotDispenseDays: yup
      .number()
      .typeError("Do not dispense days must be a number")
      .required("This field is required")
      .min(0, "Do not dispense days cannot be less than 0"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(createDispensationSchema),
  });

  const notify = () =>
    toast.success("Dispensation-detail Created Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const [load, setLoad] = useState(false);
  const [drugTypeList, setDrugTypeList] = useState([]);
  const [drugLevelList, setDrugLevelList] = useState([]);

  const navigate = useNavigate();

  const rowStyles = {
    marginTop: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "30px",
  };

  const textBoxBody = {
    marginLeft: "305px",
    width: "350px",
  };

  const textBoxBodyNew = {
    marginLeft: "238px",
    width: "350px",
  };

  const textBoxStyles = {
    fontSize: 15,
    // width: "400px",
    height: "10px",
  };

  const selectStyles = {
    width: 530,
    marginTop: 1,
  };

  const fetchAllDrugTypes = async () => {
    try {
      const res = await getAllDrugTypesService();
      if (res.status) {
        setDrugTypeList(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const fetchAllDrugLevel = async () => {
    try {
      const res = await getAllDrugLevelService();
      if (res.status) {
        setDrugLevelList(res.data?.result);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  useEffect(() => {
    fetchAllDrugTypes();
    fetchAllDrugLevel();
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/study-management/dispensations/dispensation-detail", {
      state: {
        id: dispensationId,
      },
    });
  };

  const onSubmit = async (data) => {
    try {
      setLoad(true);
      setShowDialog(false);
      data.dispensationId = dispensationId;
      const res = await createNewDispensationDetailService(data);
      if (res.status) {
        setLoad(false);
        reset();
        notify();
        navigate("/study-management/dispensations/dispensation-detail", {
          state: {
            id: dispensationId,
          },
        });
      }
    } catch (err) {
      setLoad(false);
      setShowDialog(false);
      console.log("Error: ", err.message);
      requestFailed(err.message);
    }
  };

  return (
    <>
      <>
        {load ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <BeatLoader color="#3661eb" />
            </div>
          </>
        ) : (
          <>
            <div className="content-body">
              <p
                className="study-management-link"
                style={{ fontWeight: "600" }}
              >
                <Link to="/study-management">Manage Your Study</Link> |{" "}
                <Link to="/study-management/dispensations">Dispensations</Link>{" "}
                |{" "}
                <button
                  style={{
                    background: "none",
                    color: "#337AB7",
                    border: "none",
                    padding: "0",
                    font: "inherit",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(
                      "/study-management/dispensations/dispensation-detail",
                      {
                        state: {
                          id: dispensationId,
                        },
                      }
                    );
                  }}
                >
                  Dispensation detail
                </button>
                |{" "}
                <button
                  style={{
                    background: "none",
                    color: "#337AB7",
                    border: "none",
                    padding: "0",
                    font: "inherit",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/study-management/dispensations/create-detail`, {
                      state: {
                        id: dispensationId,
                      },
                    });
                  }}
                >
                  Create Dispensation Detail
                </button>
                -{" "}
                <b style={{ color: "gray" }}>
                  {sessionStorage?.getItem("studyName")}
                </b>
              </p>

              <Row style={rowStyles}>
                <Col md={6}>
                  <div className="study-management-head-start">
                    <p className="study-management-heading">
                      Create Dispensations Details
                    </p>
                  </div>
                </Col>
                <Col md={6}></Col>
              </Row>

              <div>
                <DialogBox
                  showDialog={showPrompt}
                  confirmNavigation={confirmNavigation}
                  cancelNavigation={cancelNavigation}
                />
              </div>

              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ height: "auto", width: "100%" }}
                autoComplete="off"
              >
                <div className="createDispensationFormBody">
                  <div className="createDispensationInput">
                    <p style={labelStyles}>
                      Drug Type Id {/* {errors.drugTypeId?.message ? ( */}
                      <span className="error-highlight">*</span>
                      {/* ) : (
                    <></>
                  )} */}
                    </p>
                    <FormControl sx={selectStyles}>
                      <Select
                        name="drugTypeId"
                        defaultValue={""}
                        inputProps={{
                          style: textBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...register("drugTypeId", {
                          onChange: (e) => {
                            setValue("drugTypeId", e.target.value, {
                              shouldValidate: true,
                            });
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em value={"-1"}>None</em>
                        </MenuItem>
                        {drugTypeList?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.drugTypeId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.drugTypeId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {/* {errors.drugTypeId && (
                    <p className="error-text">{errors.drugTypeId.message}</p>
                  )} */}
                    </FormControl>
                  </div>

                  <div className="createDispensationInput">
                    <p style={labelStyles}>
                      Drug Level Id {/* {errors.dosageLevelId?.message ? ( */}
                      <span className="error-highlight">*</span>
                      {/* ) : (
                    <></>
                  )} */}
                    </p>
                    <FormControl sx={selectStyles}>
                      <Select
                        name="dosageLevelId"
                        defaultValue={""}
                        inputProps={{
                          style: textBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...register("dosageLevelId", {
                          onChange: (e) => {
                            setValue("dosageLevelId", e.target.value, {
                              shouldValidate: true,
                            });
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {drugLevelList?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.dosageLevelId && (
                        <>
                          <div className="createCaregiverFlexEnd">
                            <div className="createCaregiverError">
                              <span className="error-text">
                                {errors.dosageLevelId.message}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {/* {errors.dosageLevelId && (
                    <p className="error-text">{errors.dosageLevelId.message}</p>
                  )} */}
                    </FormControl>
                  </div>

                  <div className="createDispensationInput">
                    <Row>
                      <Col md={1} style={{ fontWeight: "bold" }}>
                        <p style={labelStyles}>
                          Quantity {/* {errors.dosageLevelId?.message ? ( */}
                          <span className="error-highlight">*</span>
                          {/* ) : (
                    <></>
                  )} */}
                        </p>
                      </Col>
                      <Col md={11}>
                        <TextField
                          type="number"
                          style={textBoxBody}
                          inputProps={{
                            style: textBoxStyles,
                          }}
                          {...register("quantity", {
                            onChange: (e) => {
                              setShowDialog(true);
                            },
                          })}
                        />
                        {errors.quantity && (
                          <>
                            <div
                              // className="createCaregiverFlexEnd"
                              style={{ paddingLeft: "300px" }}
                            >
                              <div
                              // className="createCaregiverError"
                              >
                                <span
                                  className="error-text"
                                  style={{ color: "red" }}
                                >
                                  {errors.quantity.message}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="createDispensationInput">
                    <Row>
                      <Col md={2} style={{ fontWeight: "bold" }}>
                        <p style={labelStyles}>
                          Do not dispense days{" "}
                          {/* {errors.dosageLevelId?.message ? ( */}
                          <span className="error-highlight">*</span>
                          {/* ) : (
                    <></>
                  )} */}
                        </p>
                      </Col>
                      <Col md={10}>
                        <TextField
                          type="number"
                          style={textBoxBodyNew}
                          inputProps={{
                            style: textBoxStyles,
                          }}
                          {...register("doNotDispenseDays", {
                            onChange: (e) => {
                              setShowDialog(true);
                            },
                          })}
                        />

                        {errors.doNotDispenseDays && (
                          <>
                            <div
                              // className="createCaregiverFlexEnd"
                              style={{ paddingLeft: "235px" }}
                            >
                              <div
                              // className="createCaregiverError"
                              >
                                <span
                                  className="error-text"
                                  style={{ color: "red" }}
                                >
                                  {errors.doNotDispenseDays.message}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>

                <Row style={rowStyles}>
                  <Col md={8}></Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      {/* <Link to="/study-management-builder"> */}
                      <button
                        onClick={(e) => {
                          handleCancel(e);
                        }}
                        className="study-management-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="study-management-head-end">
                      {/* <Link to="/study-management-builder"> */}
                      <button
                        type="submit"
                        className="study-management-create-btn-md"
                      >
                        Create
                      </button>
                    </div>
                  </Col>
                </Row>
              </Box>
            </div>
          </>
        )}
      </>
    </>
  );
};

export default CreateDispensstionDetails;
