import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, MenuItem, TextField } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import {
  getAllTreatments,
  deleteTreatment,
  getAllExportTreatment,
} from "../../../../services/treatments";

import "../StudySetup.css";
import { useTreatmentManagement } from "../../../../context/studyManagement/Treatment/TreatmentContext";
import useTreatmentDetails from "../../../../hooks/Api/useTreatmentDetails";
import { CustomPagination } from "../../../../components/CustomPagination";
import {
  mapDrugDataPdf,
  mapTreatmentDataPdf,
} from "../../../../utils/dataMapping";
import {
  getDownloadReport,
  postExportExcel,
  postExportPDF,
} from "../../../../services/sponsors";
import {
  LoadingOverlay,
  NoRowsOverlay,
} from "../../../../components/CutomOverlays";
import DeleteModal from "../../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const Treatment = () => {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);
  const [treatmentAllData, setTreatmentAllData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    description: true,
    notes: true,
    isEnabled: true,
    lastUpdate: true,
    edit: true,
    delete: true,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const {
    state,
    setPageNumber,
    setPageSize,
    setSorting,
    setFilters,
    dispatch,
  } = useTreatmentManagement();
  const {
    treatmentData,
    pageNumber,
    pageSize,
    sortingType,
    sortingField,
    filters,
    totalCount,
  } = state;

  const { treatmentQuery, deleteTreatmentMutation } = useTreatmentDetails();
  const { isLoading } = treatmentQuery;

  useEffect(() => {
    if (treatmentQuery.isFetching) {
      dispatch({ type: "DISABLE_LOADING" });
    }
  }, [treatmentQuery.isFetching, dispatch]);
  const notifyDelete = () =>
    toast.success("Treatment Deleted Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumnVisibilityChange = (newModel) => {
    setColumnVisibilityModel(newModel);
  };

  const fetchAllDataForExport = async () => {
    setLoad(true);
    try {
      const res = await getAllExportTreatment();
      if (res.status === 200) {
        const mappedData = mapTreatmentDataPdf(res.data);
        setTreatmentAllData(mappedData);
        setLoad(false);
        return res.data;
      }
    } catch (err) {
      console.log("ERROR: ", err);
      setLoad(false);
    }
    return [];
  };

  useEffect(() => {
    fetchAllDataForExport();
  }, []);

  const GridExportToPDF = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportPDF(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], { type: "application/pdf" });
                saveAs(blob, "Treatment.pdf");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export PDF
      </MenuItem>
    );
  };

  const GridExportToExcel = (props) => {
    return (
      <MenuItem
        onClick={async () => {
          try {
            const res = await postExportExcel(props.rows);
            if (res.status === 200) {
              const res2 = await getDownloadReport(res.data);
              if (res2.status === 200) {
                const blob = new Blob([res2.data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(blob, "Treatment.xlsx");
              }
            }
            props.hideMenu?.();
          } catch (err) {
            console.log("ERROR: ", err);
          }
        }}
      >
        Export Excel
      </MenuItem>
    );
  };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridExportToExcel rows={treatmentAllData} />
      <GridExportToPDF rows={treatmentAllData} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <CustomExportButton />
    </GridToolbarContainer>
  );

  const handleDelete = (id) => {
    deleteTreatmentMutation.mutate(id, {
      onSuccess: () => {
        fetchAllDataForExport();
        notifyDelete();
      },
      onError: (error) => {
        requestFailed(error.message);
      },
    });
  };

  const rows = treatmentData.map((row) => ({
    id: row.id,
    description: row.description || "-",
    notes: row.notes || "-",
    lastUpdate: row.lastUpdate || "-",
    edit: row.id,
    delete: row.id,
  }));

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 220,
      hide: columnVisibilityModel.id,
      filterable: false,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 220,
      hide: columnVisibilityModel.description,
      filterable: true,
      sortable: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 220,
      hide: columnVisibilityModel.description,
      filterable: true,
      sortable: true,
    },
    {
      field: "lastUpdate",
      hide: true,
      headerName: "Last Update",
      flex: 1,
      minWidth: 220,
      hide: columnVisibilityModel.lastUpdate,
      filterable: false,
      sortable: true,
    },
    {
      field: "edit",
      headerName: "Edit",
      hide: columnVisibilityModel.edit,
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              navigate(`/study-management/edit-treatment`, {
                state: {
                  id: id,
                },
              });
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      hide: columnVisibilityModel.delete,
      filterable: false,
      sortable: false,
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setSelectedId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      await handleDelete(selectedId);
      setLoad(false);
      notifyDelete();
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      requestFailed(err.message);
    } finally {
      setShowConfirmModal(false);
    }
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0];
      setSorting(sort, field);
    } else {
      setSorting("asc", "description");
    }
  };

  const handleFilterChange = (filterModel) => {
    setFilters(filterModel.items);
  };

  return (
    <ThemeProvider theme={getMuiTheme}>
      <div className="content-body">
        <p className="study-management-link" style={{ fontWeight: "600" }}>
          <Link to="/study-management">Manage Your Study</Link> |{" "}
          <Link to="/study-management/treatment">Treatment</Link> -
          <b style={{ color: "gray" }}>
            {sessionStorage?.getItem("studyName")}
          </b>
        </p>
        <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
          <Col md={6}>
            <div className="study-management-head-start">
              <p className="study-management-heading">Treatment</p>
            </div>
          </Col>
          <Col md={6}>
            <Link to="/study-management/create-treatment">
              <div className="study-management-head-end">
                <button className="study-management-create-btn-md">
                  Add New
                </button>
              </div>
            </Link>
          </Col>
        </Row>

        <Box
          sx={{
            height: 669,
            width: "100%",
            "& .MuiDataGrid-columnHeaders": {
              minHeight: "58px !important",
            },
          }}
        >
          {" "}
          <ThemeProvider theme={getMuiTheme}>
            <StripedDataGrid
              className="allQuestionnaire-grid"
              rows={rows}
              columns={columns}
              pageSize={pageSize}
              columnVisibilityModel={columnVisibilityModel}
              loading={isLoading}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              components={{
                Toolbar: CustomToolbar,
                NoRowsOverlay: NoRowsOverlay,
                LoadingOverlay: LoadingOverlay,
              }}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              filterMode="server"
              onFilterModelChange={handleFilterChange}
              sortingMode="server"
              sortModel={
                sortingField && sortingType
                  ? [{ field: sortingField, sort: sortingType }]
                  : []
              }
              onSortModelChange={handleSortModelChange}
            />
            <CustomPagination
              currentPage={pageNumber}
              totalCount={totalCount || 0}
              pageSize={pageSize}
              size="large"
              onPageChange={(event, page) =>
                dispatch({ type: "SET_PAGE_NUMBER", payload: page })
              }
              onPageSizeChange={(event) =>
                dispatch({
                  type: "SET_PAGE_SIZE",
                  payload: event.target.value,
                })
              }
            />
          </ThemeProvider>
          <Row style={{ marginTop: "3%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <div className="createSponsor-buttons">
                <Link to="/study-management">
                  <button className="backToDrugButton">Back</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Box>
      </div>
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Treatment data"}
        />
      )}
    </ThemeProvider>
  );
};

export default Treatment;
