import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import { deleteVisit, getAllVisits } from "../../../../services/visits";
import "../StudySetup.css";
import DeleteModal from "../../../../components/DeleteModal";

export const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey["200"],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const viewButtonStyle = {
  cursor: "pointer",
  textAlign: "center",
  color: "white",
  backgroundColor: "#337ab7",
  padding: "8px",
  fontSize: "12px",
  border: "none",
  borderRadius: "5px",
};

const addButtonStyle = {
  cursor: "pointer",
  textAlign: "center",
  color: "#337ab7",
  backgroundColor: "white",
  padding: "8px",
  fontSize: "12px",
  border: "2px solid #337ab7",
  borderRadius: "5px",
};

const Visits = () => {
  const navigate = useNavigate();

  const [load, setLoad] = React.useState(false);
  const [visitsData, setVisitsData] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // This is for scrolling the page to the top specially when the user comes from the Create visit page.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchAllVisitsData = async () => {
    setLoad(true);
    try {
      const res = await getAllVisits();
      if (res?.status) {
        setVisitsData(res.data?.result);
        setLoad(false);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  React.useState(() => {
    fetchAllVisitsData();
  }, []);

  const notifyDelete = () =>
    toast.success("Visit Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const notifyDeleteMsg = () => {
    toast.warn("Vist is already in used", {
      toastId: "form-creation",
    });
  };

  const requestFailed = (msg) =>
    toast.error("Something went wrong", {
      // theme: "colored",
      toastId: "requestFailed",
    });

  const rows = visitsData.map((row) => ({
    id: row.id,
    note: row.notes,
    name: row.name,
    visitOrder: row.visitOrder,
    daysExpected: row.daysExpected,
    windowAfter: row.windowAfter,
    questionnaires: row.visitQuestionnaires?.length,
    edit: row.id,
    delete: row.id,
    viewQuestionnaires: row.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "name",
      // hide: true,
      headerName: "Name",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Name"}</div>,
      renderCell: (params) => {
        const name = params.row.name;

        return (
          <div className="grid-body">
            <p>{name}</p>
          </div>
        );
      },
    },
    {
      field: "note",
      // hide: true,
      headerName: "Note",
      width: 180,
      renderHeader: () => <div className="grid-heading">{"Note"}</div>,
      renderCell: (params) => {
        const note = params.row.note;

        return (
          <div className="grid-body">
            <p>{note}</p>
          </div>
        );
      },
    },
    {
      field: "visitOrder",
      // hide: true,
      headerName: "Visit Order",
      width: 150,
      renderHeader: () => <div className="grid-heading">{"Visit Order"}</div>,
      renderCell: (params) => {
        const visitOrder = params.row.visitOrder;

        return (
          <div className="grid-body">
            <p>{visitOrder}</p>
          </div>
        );
      },
    },
    {
      field: "daysExpected",
      // hide: true,
      headerName: "Days Expected",
      width: 150,
      renderHeader: () => <div className="grid-heading">{"Days Expected"}</div>,
      renderCell: (params) => {
        const daysExpected = params.row.daysExpected;

        return (
          <div className="grid-body">
            <p>{daysExpected}</p>
          </div>
        );
      },
    },
    {
      field: "windowAfter",
      // hide: true,
      headerName: "Window After",
      width: 150,
      renderHeader: () => <div className="grid-heading">{"Window After"}</div>,
      renderCell: (params) => {
        const windowAfter = params.row.windowAfter;

        return (
          <div className="grid-body">
            <p>{windowAfter}</p>
          </div>
        );
      },
    },
    {
      field: "questionnaires",
      // hide: true,
      headerName: "Questionnaires",
      width: 150,
      renderHeader: () => (
        <div className="grid-heading">{"Questionnaires"}</div>
      ),
      renderCell: (params) => {
        const questionnaires = params.row.questionnaires;

        return (
          <div className="grid-body">
            <p>{questionnaires}</p>
          </div>
        );
      },
    },
    {
      field: "edit",
      // hide: true,
      headerName: "Edit",
      renderHeader: () => <div className="grid-heading">{"Edit"}</div>,
      width: 100,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              navigate(`/study-management/visits/edit`, {
                state: {
                  id: id,
                },
              });
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "viewQuestionnaires",
      // hide: true,
      headerName: "View Questionnaires",
      renderHeader: () => (
        <div className="grid-heading">{"View Questionnaires"}</div>
      ),
      width: 200,
      renderCell: (params) => {
        const questionnaires = params.row.questionnaires;
        const id = params.row.viewQuestionnaires;

        return (
          <button
            style={questionnaires !== 0 ? viewButtonStyle : addButtonStyle}
            onClick={async () => {
              if (questionnaires !== 0) {
                navigate(`/study-management/visits/visit-questionnaire`, {
                  state: {
                    id: id,
                  },
                });
              } else {
                navigate("/study-management/visits/create-questionnaire", {
                  state: {
                    id: id,
                  },
                });
              }
            }}
          >
            {questionnaires !== 0 ? "View Questionnaires" : "Add Questionnaire"}
          </button>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 100,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await deleteVisit(deleteId);

      if (res.status) {
        setLoad(false);
        fetchAllVisitsData();
        setShowConfirmModal(false);
        if (!res?.data?.message?.includes("The")) notifyDelete();
        else {
          notifyDeleteMsg();
        }
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link>-{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Visits</p>
              </div>
            </Col>
            <Col md={6}>
              <Link to="/study-management/visits/create">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-md">
                    Create Visit
                  </button>
                </div>
              </Link>
            </Col>
          </Row>

          <Box sx={{ height: 400, width: "100%", display: "flex" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allQuestionnaire-grid"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Visit"}
        />
      )}
    </>
  );
};

export default Visits;
