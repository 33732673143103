import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, MenuItem, Button, styled } from "@mui/material";
import { schema } from "./utils";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";
import { fontSize, width } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiFormHelperText-root": {
    color: "red", // Custom color for the error message
    fontSize: "1rem", // Custom font size
  },
  "& .MuiInputLabel-root": {
    fontSize: "1.4rem", // Example: apply a custom font size to the label
  },
  "& .MuiOutlinedInput-root": {
    fontSize: "1.4rem",
    width: "95%",
  },
}));

const FormField = ({ name, label, control }) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <CustomTextField
        {...field}
        label={label}
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
        fullWidth
        margin="normal"
      />
    )}
  />
);

const SubjectForm = ({ onSubmit }) => {
  const {
    createSubjectAttributetConfigurationDetailMutation,
    businessDetailsQuery,
    dataTypeDetailsQuery,
  } = useSubjectAttributesDetails();
  const { data } = businessDetailsQuery;
  const { data: dataType } = dataTypeDetailsQuery;
  const dataTypeItem = dataType?.map((dataItem) => dataItem.name) || [];
  const businessRule = [
    "No business rule",
    ...(data?.map((businessitem) => businessitem.name) || []),
  ];

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      description: "",
      textKey: "",
      errorDescription: "",
      errorMessageKey: "",
      valueDescription: "",
      valueDescriptionKey: "",
      maximumLength: "",
      minimumValue: "",
      maximumValue: "",
      defaultValue: "",
      dataType: "",
      businessRule: "",
      businessRuleQuestionText: "",
      businessRuleQuestionTranslationKey: "",
      errorTranslationKey: "",
      translationKey: "",
      valueDescriptionTranslationKey: "",
      questionTranslationKey: "",
    },
  });

  const handleFormSubmit = (formData) => {
    // Set businessRule to null if "No business rule" is selected
    const payload = {
      ...formData,
      businessRule:
        formData.businessRule === "No business rule"
          ? null
          : formData.businessRule,
    };
    console.log(payload, "form data sub");
    onSubmit(payload);
    reset();
  };

  return (
    <div className="p-4">
      <div className="subject-form">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormField name="description" label="Description" control={control} />
          <FormField name="textKey" label="Text Key" control={control} />
          <FormField
            name="errorDescription"
            label="Error Description"
            control={control}
          />
          <FormField
            name="errorMessageKey"
            label="Error Message Key"
            control={control}
          />
          <FormField
            name="valueDescription"
            label="Value Description"
            control={control}
          />
          <FormField
            name="valueDescriptionKey"
            label="Value Description Key"
            control={control}
          />
          <FormField
            name="maximumLength"
            label="Maximum Length"
            control={control}
          />
          <FormField
            name="defaultValue"
            label="Default Value"
            control={control}
          />
          <FormField
            name="errorTranslationKey"
            label="ErrorTranslationKey"
            control={control}
          />
          <FormField
            name="translationKey"
            label="TranslationKey"
            control={control}
          />
          <FormField
            name="minimumValue"
            label="Minimum Value"
            control={control}
          />
          <FormField
            name="maximumValue"
            label="Maximum Value"
            control={control}
          />
          <FormField
            name="valueDescriptionTranslationKey"
            label="ValueDescriptionTranslationKey"
            control={control}
          />
          <FormField
            name="questionTranslationKey"
            label="QuestionTranslationKey"
            control={control}
          />

          <Controller
            name="dataType"
            control={control}
            render={({ field, fieldState }) => (
              <CustomTextField
                {...field}
                select
                label="Data Type"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                margin="normal"
              >
                {dataTypeItem?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />

          <Controller
            name="businessRule"
            control={control}
            render={({ field, fieldState }) => (
              <CustomTextField
                {...field}
                select
                label="Business Rule"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                fullWidth
                margin="normal"
              >
                {businessRule?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomTextField>
            )}
          />

          <FormField
            name="businessRuleQuestionText"
            label="Business Rule Question text"
            control={control}
          />
          <FormField
            name="businessRuleQuestionTranslationKey"
            label="Business Rule Question Translation key"
            control={control}
          />
        </form>
      </div>
      <Button
        type="submit"
        variant="contained"
        style={{ marginTop: "20px", marginBottom: "30px" }}
        color="primary"
        className="mt-4 mb-3 py-5 px-8 !text-xl study-management-create-btn-md"
      >
        Add Attribute
      </Button>
    </div>
  );
};

export default SubjectForm;
