import { studyServer } from "../../../../../services/study_axios";

export const getAllTabDataService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/StudyCustom/GetAllGroupedValues`, [])
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateValueService = (key, value) => {
  let api = "";
  if (value !== "") {
    api = `app/StudyCustom/Update?Key=${key}&Value=${value}`;
  } else {
    api = `app/StudyCustom/Update?Key=${key}`;
  }
  return new Promise((resolve, reject) => {
    studyServer
      .put(api)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
