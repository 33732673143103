import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation, useBlocker } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import "../StudySetup.css";
import useTreatmentDetails from "../../../../hooks/Api/useTreatmentDetails";

const EditTreatment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.id;

  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [blockedNavigation, setBlockedNavigation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { updateTreatmentMutation, treatmentByIdQuery } = useTreatmentDetails();
  const { data: treatmentData, isLoading, error } = treatmentByIdQuery(id);

  const treatmentSchema = yup.object().shape({
    description: yup.string().required("This field is required"),
    notes: yup.string().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(treatmentSchema),
  });

  useEffect(() => {
    if (treatmentData) {
      const { description, notes } = treatmentData;
      reset({ description, notes });
    }
  }, [treatmentData, reset]);

  // const shouldBlock = useCallback(
  //   ({ currentLocation, nextLocation }) => {
  //     if (isDirty && !submitting && !blockedNavigation) { // Check submitting state
  //       setBlockedNavigation(true);
  //       setPendingNavigation(() => () => navigate(nextLocation.pathname));
  //       setOpenConfirmDialog(true);
  //       return true;
  //     }
  //     return false;
  //   },
  //   [isDirty, navigate, submitting, blockedNavigation] // Add submitting to dependencies
  // );

  // useBlocker(shouldBlock, isDirty);

  const notify = () =>
    toast.success("Treatment Edited Successfully", {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, { toastId: "requestFailed" });

  const onSubmit = (data) => {
    setSubmitting(true);
    setBlockedNavigation(false);
    setShowDialog(false);
    setLoad(true);

    const { description, notes } = data;
    const updatedTreatment = { description, lastUpdate: null, notes };

    updateTreatmentMutation.mutate(
      { id, updatedTreatment },
      {
        onSuccess: () => {
          notify();
          reset(data);
          navigate("/study-management/treatment");
        },
        onError: (error) => {
          requestFailed(error.message);
        },
        onSettled: () => {
          setLoad(false);
          setSubmitting(false);
        },
      }
    );
  };

  const handleCancel = (e) => {
    // if (isDirty) {
    //   setOpenConfirmDialog(true);
    // } else {
    // setBlockedNavigation(false);
    // navigate("/study-management/treatment");
    // }
    if (isDirty) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/treatment");
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/treatment");
    }
  };

  const handleDialogClose = () => {
    setOpenConfirmDialog(false);
    setBlockedNavigation(true);
    setPendingNavigation(null);
  };

  const handleConfirmNavigation = () => {
    setBlockedNavigation(false);
    setOpenConfirmDialog(false);
    if (pendingNavigation) {
      pendingNavigation();
      setPendingNavigation(null);
    }
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const rowStyles = {
    marginTop: "2%",
    marginBottom: "2%",
  };

  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "35px",
  };

  const textBoxBody = {
    marginLeft: "10px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "400px",
    height: "10px",
  };

  const rowSpacing = {
    marginTop: "2%",
  };

  const textAreaStyles = { fontSize: 15, width: "500px", height: "50px" };

  return (
    <>
      {load ? (
        <div style={loadContainerStyles}>
          <BeatLoader color="#3661eb" />
        </div>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/treatment">Treatment</Link> |{" "}
            <span className="study-edit-link">Edit Treatment</span> -
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={rowStyles}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">Edit Treatment</p>
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>

          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              if (isDirty) {
                setShowDialog(true);
              } else {
                toast.error("No changes made to save.", {
                  toastId: "no-changes",
                });
              }
            }}
            sx={{ height: 200, width: "100%" }}
            autoComplete="off"
          >
            <Row>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Description <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={10}>
                <TextField
                  inputProps={{
                    style: textAreaStyles,
                  }}
                  multiline
                  rows={1}
                  {...register("description")}
                />
                {errors.description && (
                  <div className="createCaregiverFlexEnd">
                    <div className="createCaregiverError">
                      <span className="error-text">
                        {errors.description.message}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row style={rowSpacing}>
              <Col md={2}>
                <p className="descriptionLabel" style={labelStyles}>
                  Notes <span className="error-highlight">*</span>
                </p>
              </Col>
              <Col md={10}>
                <TextField
                  inputProps={{
                    style: textAreaStyles,
                  }}
                  multiline
                  rows={1}
                  {...register("notes")}
                />
                {errors.notes && (
                  <div className="createCaregiverFlexEnd">
                    <div className="createCaregiverError">
                      <span className="error-text">{errors.notes.message}</span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row style={rowSpacing}>
              <Col md={8}></Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="study-management-cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </Col>
              <Col md={2}>
                <div className="study-management-head-end">
                  <button
                    type="submit"
                    className="study-management-create-btn-md"
                  >
                    Update
                  </button>
                </div>
              </Col>
            </Row>
          </Box>

          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <DialogTitle
              id="confirm-dialog-title"
              className="!text-2xl !font-semibold"
            >
              Confirm Submission
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="confirm-dialog-description"
                className="!text-xl"
              >
                Are you sure you want to submit the changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDialog(false)}
                color="primary"
                className="!text-xl"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                color="primary"
                autoFocus
                className="!text-xl"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openConfirmDialog}
            onClose={handleDialogClose}
            aria-labelledby="confirm-leave-dialog-title"
            aria-describedby="confirm-leave-dialog-description"
          >
            <DialogTitle
              id="confirm-leave-dialog-title"
              className="!text-2xl !font-semibold"
            >
              Unsaved Changes
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="confirm-leave-dialog-description"
                className="!text-xl"
              >
                You have unsaved changes. Are you sure you want to leave this
                page?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleDialogClose}
                color="primary"
                className="!text-xl"
              >
                Stay
              </Button>
              <Button
                onClick={handleConfirmNavigation}
                color="primary"
                autoFocus
                className="!text-xl"
              >
                Leave
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default EditTreatment;
