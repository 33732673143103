import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import DialogBox from "../../../../../components/DialogBox";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import { Box } from "@mui/system";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  addReviewCalculationService,
  addVisitQuestionnaireCalculationService,
  addVisitQuestionnaireRoleService,
  addVisitQuestionnaireStudyRoleService,
  editVisitQuestionnaireService,
  getAllDropdownsForVisitQuestionnaireService,
  getQuestionnaireDropdownListService,
  getVisitQuestionnaireDataById,
  removeQuestionnaireCalculationService,
  removeReviewCalculationQuestionnaireService,
  removeReviewVisitQuestionnaireService,
  removeStudyRole,
  updateReviewVisitQuestionnaireService,
} from "../../../../../services/visits";
import { toast } from "react-toastify";
import QuestionnaireTableComp from "./QuestionnaireTableComp";

export const rowStyles = {
  marginTop: "2%",
};

export const buttonLinkStyle = {
  background: "none",
  color: "#337AB7",
  border: "none",
  padding: "0",
  font: "inherit",
  cursor: "pointer",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const demoData = [
  {
    id: 1,
    name: "Test",
  },
  {
    id: 1,
    name: "Test",
  },
  {
    id: 1,
    name: "Test",
  },
];

const EditVisitQuestionnaire = () => {
  const labelStyles = {
    display: "flex",
    alignItems: "center",
    height: "20px",
  };

  const textBoxBody = {
    marginLeft: "10px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "500px",
    height: "10px",
  };

  const loadContainerStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
  };

  const selectStyles = {
    width: 530,
    marginTop: 1,
  };

  const addButtonStyle = {
    cursor: "pointer",
    textAlign: "center",
    color: "white",
    backgroundColor: "#337ab7",
    padding: "12px",
    fontSize: "15px",
    border: "none",
    borderRadius: "5px",
  };

  const createQuestionnaireSchema = yup.object().shape({
    questionnaireId: yup.string().required("This field is required"),
    sequence: yup
      .number()
      .typeError("Value must be a number")
      .required("This field is required")
      .min(0, "Value cannot be less than 0"),
    isAudioEnabled: yup.bool().oneOf([true, false]),
    isVideoEnabled: yup.bool().oneOf([true, false]),
  });

  const createCalculationSchema = yup.object().shape({
    calculation: yup.string().required("This field is required"),
  });

  const createStudyRoleSchema = yup.object().shape({
    studyRole: yup.string().required("This field is required"),
  });

  const createVisitRoleSchema = yup.object().shape({
    visitRole: yup.string().required("This field is required"),
  });

  const createCalculationRoleSchema = yup.object().shape({
    calculationRole: yup.string().required("This field is required"),
  });

  const createReviewRoleSchema = yup.object().shape({
    reviewRole: yup.string().required("This field is required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(createQuestionnaireSchema),
  });

  const {
    register: registerCalculation,
    handleSubmit: handleSubmitCalculation,
    reset: resetCalculation,
    setValue: calculationSetValue,
    formState: { errors: errorsCalculation, isDirty: dirtyCalculation },
  } = useForm({
    resolver: yupResolver(createCalculationSchema),
  });

  const {
    register: registerStudyRole,
    handleSubmit: handleStudyRole,
    setValue: studyRoleSetValue,
    reset: resetStudyRole,
    formState: { errors: errorsStudyRole, isDirty: dirtyStudy },
  } = useForm({
    resolver: yupResolver(createStudyRoleSchema),
  });

  const {
    register: registerVisitRole,
    handleSubmit: handleVisitRole,
    setValue: visitRoleSetValue,
    reset: resetVisit,
    formState: { errors: errorsVisitRole, isDirty: dirtyRole },
  } = useForm({
    resolver: yupResolver(createVisitRoleSchema),
  });

  const {
    register: registerCalculationRole,
    handleSubmit: handleCalculationRole,
    reset: resetReviewCalculation,
    setValue: calculationRoleSetValue,
    formState: { errors: errorsCalculationRole, isDirty: dirtyCalculationRole },
  } = useForm({
    resolver: yupResolver(createCalculationRoleSchema),
  });

  const {
    register: registerReviewRole,
    handleSubmit: handleReviewRole,
    reset: resetReviewRole,
    setValue: reviewRoleSetValue,
    formState: { errors: errorsReviewRole, isDirty: dirtyReview },
  } = useForm({
    resolver: yupResolver(createReviewRoleSchema),
  });

  const notify = (msg) =>
    toast.success(msg, {
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error(`${msg}`, {
      toastId: "requestFailed",
    });

  const navigate = useNavigate();
  const location = useLocation();
  const id = location?.state?.id;
  const visitId = location?.state?.visitId;

  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [value, setTabValue] = useState(0);

  const [questionnaireDropdownList, setQuestionnaireDropdownList] = useState(
    []
  );
  const [calculationDropdown, setCalculationDropdown] = useState([]);
  const [reviewCalculationDropdown, setReviewCalculationDropdown] = useState(
    []
  );
  const [studyRoleDropdown, setStudyRoleDropdown] = useState([]);
  const [visitDropdown, setVisitDropdown] = useState([]);

  const [formData, setFormData] = useState({
    questionnaireIdState: "",
    isAudioEnabledState: "",
    isVideoEnabledState: "",
    sequenceState: "",
  });

  const [studyRolesListing, setStudyRoleListing] = useState([]);
  const [visitListingData, setVisitListingData] = useState([]);
  const [calcualationListingData, setCalculationListingData] = useState([]);
  const [reviewCalculationListingData, setReviewCalculationListingData] =
    useState([]);
  const [reviewQuestionnaireIdState, setReviewQuestionnaireIdState] =
    useState(null);
  const {
    isAudioEnabledState,
    isVideoEnabledState,
    questionnaireIdState,
    sequenceState,
  } = formData;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (visitId) {
      getQuestionnaireDropdownList(visitId);
    }
  }, [visitId]);

  useEffect(() => {
    if (id) {
      getVisitQuestionnaireData(id);
    }
  }, [id]);

  const onSubmit = async (data) => {
    setLoad(true);
    try {
      data.visitId = visitId;
      data.requiresReview = false;
      data.reviewQuestionnaireId = null;
      const res = await editVisitQuestionnaireService(data, id);
      if (res?.status) {
        notify("Visit Questionnaire Updated Successfully !");
        setLoad(false);
        reset();
        navigate("/study-management/visits/visit-questionnaire", {
          state: {
            id: visitId,
          },
        });
      }
    } catch (err) {
      requestFailed(err?.message);
      setLoad(false);
      console.log("Error: ", err?.message);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    // navigate("/study-management/visits/visit-questionnaire", {
    //   state: {
    //     id: visitId,
    //   },
    // });
    if (
      isDirty ||
      dirtyCalculation ||
      dirtyCalculationRole ||
      dirtyReview ||
      dirtyRole ||
      dirtyStudy
    ) {
      const confirm = window?.confirm(
        "You have unsaved changes,Are you sure want to leave ?"
      );
      if (confirm) {
        navigate("/study-management/visits/visit-questionnaire", {
          state: {
            id: visitId,
          },
        });
      } else {
        e.preventDefault();
      }
    } else {
      navigate("/study-management/visits/visit-questionnaire", {
        state: {
          id: visitId,
        },
      });
    }
  };

  const getQuestionnaireDropdownList = async (id) => {
    setLoad(true);
    try {
      const res = await getQuestionnaireDropdownListService(id);
      if (res?.status) {
        setQuestionnaireDropdownList(res?.data?.result?.questionnaires);
        setLoad(false);
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err?.message);
    }
  };

  const getVisitQuestionnaireData = async (id) => {
    setLoad(true);
    try {
      const res = await getVisitQuestionnaireDataById(id);
      if (res?.status) {
        setLoad(false);
        const data = res?.data?.result.visitQuestionnaireData;
        setFormData({
          isAudioEnabledState: data?.isAudioEnabled,
          isVideoEnabledState: data?.isVideoEnabled,
          questionnaireIdState: data?.questionnaireId,
          sequenceState: data?.sequence ? data?.sequence : 0,
        });
        setCalculationDropdown(res?.data?.result?.calculations);
        setReviewCalculationDropdown(res?.data?.result?.reviewCalculations);
        setStudyRoleDropdown(res?.data?.result?.studyRoles);
        setVisitDropdown(res?.data?.result?.visits);
        setStudyRoleListing(
          res?.data?.result?.visitQuestionnaireData
            ?.visitQuestionnaireStudyRoles
        );
        setVisitListingData(
          res?.data?.result?.visitQuestionnaireData?.visitQuestionnaireVisits
        );
        setCalculationListingData(
          res?.data?.result?.visitQuestionnaireData
            ?.visitQuestionnaireCalculations
        );
        setReviewCalculationListingData(
          res?.data?.result?.visitQuestionnaireData
            ?.visitQuestionnaireReviewCalculations
        );
        setReviewQuestionnaireIdState(
          res?.data?.result?.visitQuestionnaireData?.reviewQuestionnaireId
        );
        reset();
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err.message);
    }
  };

  const handleCalculation = async (data) => {
    setLoad(true);
    const newData = {
      visitQuestionnaireId: id,
      visitId: visitId,
      questionnaireId: questionnaireIdState,
      calculationId: data?.calculation,
    };
    try {
      const res = await addVisitQuestionnaireCalculationService(newData);
      if (res?.status) {
        setLoad(false);
        getVisitQuestionnaireData(id);
        resetCalculation();
        notify("Added Visit Questionnaire Calculation Successfulluy !");
      }
    } catch (err) {
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
      setLoad(false);
    }
  };

  const handleStudyRoleFun = async (data) => {
    setLoad(true);
    const newData = {
      visitQuestionnaireId: id,
      visitId: visitId,
      questionnaireId: questionnaireIdState,
      studyRoleId: data?.studyRole,
    };
    try {
      const res = await addVisitQuestionnaireStudyRoleService(newData);
      if (res?.status) {
        setLoad(false);
        resetStudyRole();
        getVisitQuestionnaireData(id);
        notify("Added Visit Questionnaire Study Role Successfulluy !");
      }
    } catch (err) {
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
      setLoad(false);
    }
  };

  const handleVisitRoleFun = async (data) => {
    setLoad(true);
    const newData = {
      visitQuestionnaireId: id,
      visitId: data?.visitRole,
      questionnaireId: questionnaireIdState,
    };
    try {
      const res = await addVisitQuestionnaireRoleService(newData);
      if (res?.status) {
        setLoad(false);
        resetVisit();
        getVisitQuestionnaireData(id);
        notify("Added Visit Questionnaire Role Successfulluy !");
      }
    } catch (err) {
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
      setLoad(false);
    }
  };

  const handleCalulationRoleFun = async (data) => {
    setLoad(true);
    const newData = {
      visitQuestionnaireId: id,
      calculationId: data?.calculationRole,
      questionnaireId: questionnaireIdState,
    };
    try {
      const res = await addReviewCalculationService(newData);
      if (res?.status) {
        setLoad(false);
        resetReviewCalculation();
        getVisitQuestionnaireData(id);
        notify("Added Review Calculation Successfulluy !");
      }
    } catch (err) {
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
      setLoad(false);
    }
  };

  const handleReviewRoleFun = async (data) => {
    setLoad(true);
    const newData = {
      visitQuestionnaireId: id,
      reviewQuestionnaireId: data?.reviewRole,
      questionnaireId: questionnaireIdState,
    };
    try {
      const res = await updateReviewVisitQuestionnaireService(newData);
      if (res?.status) {
        setLoad(false);
        resetReviewCalculation();
        getVisitQuestionnaireData(id);
        notify("Updated Review Questionnaire Successfulluy !");
      }
    } catch (err) {
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
      setLoad(false);
    }
  };

  const deleteQuestionnaireCalculation = async (deleteId) => {
    setLoad(true);
    const data = {
      visitQuestionnaireId: id,
      visitId: visitId,
      questionnaireId: questionnaireIdState,
      calculationId: deleteId,
    };
    try {
      const res = await removeQuestionnaireCalculationService(deleteId, data);
      if (res?.status) {
        setLoad(false);
        getVisitQuestionnaireData(id);
        resetCalculation();
        notify("Deleted Questionnaire Calculation Successfully !");
      }
    } catch (err) {
      setLoad(false);
      console.log("Error: ", err?.message);
      requestFailed(err?.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div style={loadContainerStyles}>
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link> |{" "}
            <button
              style={buttonLinkStyle}
              onClick={() => {
                navigate("/study-management/visits/visit-questionnaire", {
                  state: {
                    id: visitId,
                  },
                });
              }}
            >
              Visit Questionnaires
            </button>{" "}
            |{" "}
            <button
              style={buttonLinkStyle}
              onClick={() => {
                navigate("/study-management/visits/edit-questionnaire", {
                  state: {
                    id: id,
                    visitId: visitId,
                  },
                });
              }}
            >
              Edit Questionnaires
            </button>
            -{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>

          <div>
            <DialogBox
              showDialog={showPrompt}
              confirmNavigation={confirmNavigation}
              cancelNavigation={cancelNavigation}
            />
          </div>
          <div className="createVisitHeader">
            <h1>Edit Questionnaire</h1>
          </div>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{
                width: "fit-content",
              }}
            >
              <Tab label="Edit Visit Questionnaire" {...a11yProps(0)} />
              <Tab label="Calculations" {...a11yProps(1)} />
              <Tab label="Review Functionality" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{ height: "auto", width: "100%" }}
                autoComplete="off"
              >
                <div className="createVisitBody">
                  <div className="createVisitFormBody">
                    <div className="createVisitInput">
                      <p style={labelStyles}>
                        Questionnaire Id
                        <span className="error-highlight">*</span>
                      </p>
                      <FormControl sx={selectStyles}>
                        <Select
                          name="questionnaireId"
                          defaultValue={""}
                          value={questionnaireIdState}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                          }}
                          {...register("questionnaireId", {
                            onChange: (e) => {
                              setValue("questionnaireId", e.target.value, {
                                shouldValidate: true,
                              });
                              setFormData((prevData) => ({
                                ...prevData,
                                questionnaireIdState: e.target?.value,
                              }));
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {questionnaireDropdownList &&
                            questionnaireDropdownList?.map((item) => (
                              <MenuItem value={item?.id} key={item?.id}>
                                <em>{item?.internalName}</em>
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    {errors.questionnaireId && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.questionnaireId.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="createVisitCheckbox">
                      <p>Is Audio Enabled</p>
                      <Checkbox
                        style={{ fontSize: "15px" }}
                        checked={isAudioEnabledState}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("isAudioEnabled", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              isAudioEnabledState: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.isAudioEnabled && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.isAudioEnabled.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitCheckbox">
                      <p>Is Video Enabled</p>
                      <Checkbox
                        checked={isVideoEnabledState}
                        style={{ fontSize: "15px" }}
                        inputProps={{ "aria-label": "controlled" }}
                        {...register("isVideoEnabled", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              isVideoEnabledState: e.target?.checked,
                            }));
                          },
                        })}
                      />
                    </div>

                    {errors.isVideoEnabled && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.isVideoEnabled.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="createVisitInput">
                      <p style={labelStyles}>
                        Sequence
                        <span className="error-highlight">*</span>
                      </p>
                      <TextField
                        type={"number"}
                        style={textBoxBody}
                        value={sequenceState}
                        inputProps={{
                          style: textBoxStyles,
                          min: 0,
                        }}
                        {...register("sequence", {
                          onChange: (e) => {
                            setShowDialog(true);
                            setFormData((prevData) => ({
                              ...prevData,
                              sequenceState: e.target?.value,
                            }));
                          },
                        })}
                      />
                    </div>
                    {errors.sequence && (
                      <>
                        <div className="createVisitFlexEnd">
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errors.sequence.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}

                    <Row style={rowStyles}>
                      <Col md={8}></Col>
                      <Col md={2}>
                        <div className="study-management-head-end">
                          <button
                            onClick={(e) => {
                              handleCancel(e);
                            }}
                            className="study-management-cancel-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="study-management-head-end">
                          <button
                            type="submit"
                            className="study-management-create-btn-md"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                component="form"
                onSubmit={handleSubmitCalculation(handleCalculation)}
              >
                <div className="edit-visit-questionnaire-form-body">
                  <div style={{ width: "43vw" }}>
                    <div className="">
                      <p style={labelStyles}>
                        Add a calculation
                        {/* <span className="error-highlight">*</span> */}
                      </p>
                      <FormControl sx={selectStyles}>
                        <Select
                          name="calculation"
                          defaultValue={""}
                          inputProps={{
                            style: textBoxStyles,
                            "aria-label": "Without label",
                          }}
                          {...registerCalculation("calculation", {
                            onChange: (e) => {
                              calculationSetValue(
                                "calculation",
                                e.target.value,
                                {
                                  shouldValidate: true,
                                }
                              );
                              setShowDialog(true);
                            },
                          })}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {calculationDropdown &&
                            calculationDropdown?.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                    {errorsCalculation.calculation && (
                      <>
                        <div className="" style={{ marginTop: "30px" }}>
                          <div className="createVisitError">
                            <span
                              className="error-text"
                              style={{ color: "red" }}
                            >
                              {errorsCalculation.calculation.message}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <Row style={rowStyles}>
                      {/* <Col md={8}></Col> */}
                      <Col md={4}>
                        <div className="me-4">
                          <button
                            onClick={(e) => {
                              handleCancel(e);
                            }}
                            className="study-management-cancel-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="">
                          <button
                            type="submit"
                            className="study-management-create-btn-md"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {calcualationListingData &&
                    calcualationListingData?.length > 0 && (
                      <div style={{ width: "40vw" }}>
                        <QuestionnaireTableComp
                          heading={"Calculations"}
                          data={calcualationListingData}
                          getData={getVisitQuestionnaireData}
                          id={id}
                          setLoad={setLoad}
                          rowName={`calculation`}
                          keyName={`name`}
                          apiName={deleteQuestionnaireCalculation}
                          isCalculation={true}
                        />
                      </div>
                    )}
                </div>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div>
                <h4>
                  {`This functionality configures visit
                  ${" --> "}
                  questionnaire review functionality. The roles assigned will be
                  permitted to do the review. The visits selected will be
                  displayed on the summary review screen.`}
                </h4>
              </div>
              <div className="edit-visit-questionnaire-form-body ">
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ width: "43vw" }}
                  onSubmit={handleStudyRole(handleStudyRoleFun)}
                >
                  <div>
                    <p style={labelStyles}>
                      Add a Study Role to review this questionnaire at this
                      visit
                      {/* <span className="error-highlight">*</span> */}
                    </p>
                    <FormControl sx={selectStyles}>
                      <Select
                        name="studyRole"
                        defaultValue={""}
                        inputProps={{
                          style: textBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...registerStudyRole("studyRole", {
                          onChange: (e) => {
                            studyRoleSetValue("studyRole", e.target.value, {
                              shouldValidate: true,
                            });
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {studyRoleDropdown &&
                          studyRoleDropdown?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item?.description}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  {errorsStudyRole.studyRole && (
                    <>
                      <div style={{ marginTop: "30px" }}>
                        <div className="createVisitError">
                          <span className="error-text" style={{ color: "red" }}>
                            {errorsStudyRole.studyRole.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ marginTop: "10px" }}>
                    <button type="submit" style={addButtonStyle}>
                      Add Study Role
                    </button>
                  </div>
                </Box>
                <div className="questionnaire-listing">
                  {studyRolesListing && studyRolesListing?.length > 0 && (
                    <QuestionnaireTableComp
                      heading={"Study Role"}
                      data={studyRolesListing}
                      getData={getVisitQuestionnaireData}
                      id={id}
                      setLoad={setLoad}
                      keyName={`description`}
                      rowName={`studyRole`}
                      apiName={removeStudyRole}
                      isCalculation={false}
                    />
                  )}
                </div>
              </div>
              <hr />
              <div className="edit-visit-questionnaire-form-body">
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ width: "43vw" }}
                  onSubmit={handleVisitRole(handleVisitRoleFun)}
                >
                  <div>
                    <p style={labelStyles}>
                      Add a visit value to be displayed for review on the
                      tablet. Please note that ONLY visits that contain this
                      questionnaire may be selected.
                      {/* <span className="error-highlight">*</span> */}
                    </p>
                    <FormControl sx={selectStyles}>
                      <Select
                        name="visitRole"
                        defaultValue={""}
                        inputProps={{
                          style: textBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...registerVisitRole("visitRole", {
                          onChange: (e) => {
                            visitRoleSetValue("visitRole", e.target.value, {
                              shouldValidate: true,
                            });
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {visitDropdown &&
                          visitDropdown?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  {errorsVisitRole.visitRole && (
                    <>
                      <div style={{ marginTop: "30px" }}>
                        <div className="createVisitError">
                          <span className="error-text" style={{ color: "red" }}>
                            {errorsVisitRole.visitRole.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ marginTop: "10px" }}>
                    <button type="submit" style={addButtonStyle}>
                      Add Visit
                    </button>
                  </div>
                </Box>
                <div className="questionnaire-listing">
                  {visitListingData && visitListingData?.length > 0 && (
                    <QuestionnaireTableComp
                      data={visitListingData}
                      getData={getVisitQuestionnaireData}
                      heading={"Visit Questionnaire"}
                      id={id}
                      setLoad={setLoad}
                      keyName={`name`}
                      rowName={`visit`}
                      apiName={removeReviewVisitQuestionnaireService}
                      isCalculation={false}
                    ></QuestionnaireTableComp>
                  )}
                </div>
              </div>
              <hr />
              <div className="edit-visit-questionnaire-form-body">
                <Box
                  component="form"
                  autoComplete="off"
                  sx={{ width: "43vw" }}
                  onSubmit={handleCalculationRole(handleCalulationRoleFun)}
                >
                  <div>
                    <p style={labelStyles}>
                      Add a calculation to be displayed for review on the
                      tablet.
                      {/* <span className="error-highlight">*</span> */}
                    </p>
                    <FormControl sx={selectStyles}>
                      <Select
                        name="calculationRole"
                        defaultValue={""}
                        inputProps={{
                          style: textBoxStyles,
                          "aria-label": "Without label",
                        }}
                        {...registerCalculationRole("calculationRole", {
                          onChange: (e) => {
                            calculationRoleSetValue(
                              "calculationRole",
                              e.target.value,
                              {
                                shouldValidate: true,
                              }
                            );
                            setShowDialog(true);
                          },
                        })}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {reviewCalculationDropdown &&
                          reviewCalculationDropdown?.map((item) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  {errorsCalculationRole.calculationRole && (
                    <>
                      <div style={{ marginTop: "30px" }}>
                        <div className="createVisitError">
                          <span className="error-text" style={{ color: "red" }}>
                            {errorsCalculationRole.calculationRole.message}
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ marginTop: "10px" }}>
                    <button type="submit" style={addButtonStyle}>
                      Add Calculation
                    </button>
                  </div>
                </Box>
                <div className="questionnaire-listing">
                  {reviewCalculationListingData &&
                    reviewCalculationListingData?.length > 0 && (
                      <QuestionnaireTableComp
                        heading={"Review Calculations"}
                        data={reviewCalculationListingData}
                        getData={getVisitQuestionnaireData}
                        id={id}
                        setLoad={setLoad}
                        rowName={`calculation`}
                        keyName={`name`}
                        apiName={removeReviewCalculationQuestionnaireService}
                        isCalculation={false}
                      ></QuestionnaireTableComp>
                    )}
                </div>
              </div>
              <hr />
              <Box
                component="form"
                autoComplete="off"
                onSubmit={handleReviewRole(handleReviewRoleFun)}
              >
                <div>
                  <p style={labelStyles}>
                    Add a review questionnaire to be completed after this
                    questionnaire is marked for review (follow up questions).
                    {/* <span className="error-highlight">*</span> */}
                  </p>
                  <FormControl sx={selectStyles}>
                    <Select
                      name="reviewRole"
                      // defaultValue={""}
                      value={reviewQuestionnaireIdState}
                      inputProps={{
                        style: textBoxStyles,
                        "aria-label": "Without label",
                      }}
                      {...registerReviewRole("reviewRole", {
                        onChange: (e) => {
                          reviewRoleSetValue("reviewRole", e.target.value, {
                            shouldValidate: true,
                          });
                          setReviewQuestionnaireIdState(e?.target?.value);
                          setShowDialog(true);
                        },
                      })}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {questionnaireDropdownList &&
                        questionnaireDropdownList?.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.internalName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {errorsReviewRole.reviewRole && (
                  <>
                    <div style={{ marginTop: "30px" }}>
                      <div className="createVisitError">
                        <span className="error-text" style={{ color: "red" }}>
                          {errorsReviewRole.reviewRole.message}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ marginTop: "10px" }}>
                  <button type="submit" style={addButtonStyle}>
                    Update Review Questionnaire
                  </button>
                </div>
                <Row style={{ marginTop: "2%" }}>
                  <Col md={6}></Col>
                  <Col md={6}>
                    {/* <Link to="/study-management"> */}
                    <div className="study-management-head-end">
                      <button
                        onClick={(e) => handleCancel(e)}
                        className="study-management-create-btn-lg"
                      >
                        Back
                      </button>
                    </div>
                    {/* </Link> */}
                  </Col>
                </Row>
              </Box>
            </TabPanel>
          </Box>
        </div>
      )}
    </>
  );
};

export default EditVisitQuestionnaire;
