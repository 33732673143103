import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrashAlt, FaClone, FaCheck, FaTimes } from "react-icons/fa";
import { Container, Row, Col, Form } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";

import "../StudySetup.css";
import SubjectAttributes from "./SubjectAttributes";
import SubjectConfiguration from "./SubjectConfiguration";
const ODD_OPACITY = 0.2;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubjectParent = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDialog, setShowDialog] = React.useState(false);

  const { subjectAttributeQuery } = useSubjectAttributesDetails();
  const { data } = subjectAttributeQuery;

  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "210px",
    height: "5px",
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (window.confirm("Changes you made will not be saved. Are you sure?")) {
      navigate("/system-settings");
    }
  };

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    handleNavigation(path);
  };

  const handleNavigation = useCallback(
    (path) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (userConfirmed) {
          setShowDialog(false);
          navigate(path);
        }
      } else {
        navigate(path);
      }
    },
    [showDialog, navigate]
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (showDialog) {
        event.preventDefault();
        event.returnValue = "";
        return "Changes you made will not be saved. Are you sure?";
      }
    };

    const handlePopState = (event) => {
      if (showDialog) {
        const userConfirmed = window.confirm(
          "Changes you made will not be saved. Are you sure?"
        );
        if (!userConfirmed) {
          window.history.pushState(null, "", window.location.href);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showDialog]);

  return (
    <>
      <div className="content-body">
        <p className="study-management-link" style={{ fontWeight: "600" }}>
          <Link onClick={(e) => handleLinkClick(e, "/study-management")}>
            Manage Your Study
          </Link>{" "}
          |{" "}
          <Link
            onClick={(e) =>
              handleLinkClick(e, "/study-management/subject-attributes")
            }
          >
            Subject Attributes
          </Link>{" "}
          -
          <b style={{ color: "gray" }}>
            {sessionStorage?.getItem("studyName")}
          </b>
        </p>
        <Box sx={{ marginTop: "2%" }}>
          <Box
            sx={{ width: "100%", borderColor: "divider" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{ justifyContent: "center" }}
            >
              <Tab
                label="Subject Attributes"
                {...a11yProps(0)}
                sx={{ fontSize: "1.4rem" }}
              />
              <Tab
                label="Subject Configuration"
                {...a11yProps(1)}
                sx={{ fontSize: "1.4rem" }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Box
              sx={{ height: "auto", width: "100%" }}
              noValidate
              autoComplete="off"
            >
              <Container fluid className="h-100">
                <SubjectAttributes setShowDialog={setShowDialog} />
              </Container>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="studyManageBody">
              <div className="subjectConfiguration">
                <div className="subjectVariableHead">
                  <h1>Subject Variable</h1>
                </div>
                <SubjectConfiguration />
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

export default SubjectParent;
