import { Box } from "@mui/system";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { FaCheck, FaPencilAlt, FaTimes, FaTrashAlt } from "react-icons/fa";
import { DataGridPro, GridToolbar, gridClasses } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import {
  getVisitQuestionnaireListService,
  removeVisitQuestionnaireService,
} from "../../../../../services/visits";
import DeleteModal from "../../../../../components/DeleteModal";

const getMuiTheme = createTheme({
  typography: {
    fontSize: 25,
  },
  ".css-1x51dt5-MuiInputBase-input-MuiInput-input": {
    color: "#000000 !important",
  },
});

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const VisitQuestionnaires = () => {
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [pageName, setPageName] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const id = location.state.id;

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const notifyDelete = () =>
    toast.success("Visit Questionnaire Deleted Successfully", {
      // theme: "colored",
      toastId: "form-creation",
    });

  const requestFailed = (msg) =>
    toast.error("Something went wrong", {
      // theme: "colored",
      toastId: "requestFailed",
    });

  useEffect(() => {
    if (id) {
      fetchVisitById(id);
    }
  }, [id]);

  const getTranslationKey = (item, questionnaireData) => {
    const foundItem = questionnaireData.find((ele) => ele?.id === item?.id);
    if (foundItem) {
      return item?.questionnaire?.translationKey;
    }
    return null;
  };

  const rows = questionnaireData?.map((item) => ({
    id: item?.id,
    translationKey: getTranslationKey(item, questionnaireData),
    audioEnabled: item?.isAudioEnabled,
    videoEnabled: item?.isVideoEnabled,
    sequence: item?.sequence ? item?.sequence : 0,
    edit: item.id,
    delete: item?.id,
  }));

  const columns = [
    {
      field: "id",
      hide: true,
      headerName: "ID",
      width: 0,
      renderHeader: () => <div className="grid-heading-id">{"ID"}</div>,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <div className="grid-id">
            <p>{id}</p>
          </div>
        );
      },
    },
    {
      field: "translationKey",
      // hide: true,
      headerName: "Translation Key",
      width: 220,
      renderHeader: () => (
        <div className="grid-heading">{"Translation Key"}</div>
      ),
      renderCell: (params) => {
        const translationKey = params.row.translationKey;

        return (
          <div className="grid-body">
            <p>{translationKey}</p>
          </div>
        );
      },
    },
    {
      field: "audioEnabled",
      // hide: true,
      headerName: "Is Audio Enabled",
      width: 180,
      renderHeader: () => (
        <div className="grid-heading">{"Is Audio Enabled"}</div>
      ),
      renderCell: (params) => {
        const audioEnabled = params.row.audioEnabled;

        return (
          <div className="grid-body">
            <button
              style={{
                cursor: "pointer",
                textAlign: "center",
                background: "none",
                border: "none",
                borderRadius: "5px",
              }}
            >
              {audioEnabled ? (
                <FaCheck
                  color="#3661eb"
                  style={{
                    fontSize: "15px",
                  }}
                />
              ) : (
                <FaTimes
                  color="rgb(140 140 140)"
                  style={{
                    fontSize: "15px",
                  }}
                />
              )}
            </button>
          </div>
        );
      },
    },
    {
      field: "videoEnabled",
      // hide: true,
      headerName: "Is Video Enabled",
      width: 180,
      renderHeader: () => (
        <div className="grid-heading">{"Is Video Enabled"}</div>
      ),
      renderCell: (params) => {
        const videoEnabled = params.row.videoEnabled;

        return (
          <div className="grid-body">
            <button
              style={{
                cursor: "pointer",
                textAlign: "center",
                background: "none",
                border: "none",
                borderRadius: "5px",
              }}
            >
              {videoEnabled ? (
                <FaCheck
                  color="#3661eb"
                  style={{
                    fontSize: "15px",
                  }}
                />
              ) : (
                <FaTimes
                  color="rgb(140 140 140)"
                  style={{
                    fontSize: "15px",
                  }}
                />
              )}
            </button>
          </div>
        );
      },
    },
    {
      field: "sequence",
      // hide: true,
      headerName: "Sequence",
      width: 220,
      renderHeader: () => <div className="grid-heading">{"Sequence"}</div>,
      renderCell: (params) => {
        const sequence = params.row.sequence;

        return (
          <div className="grid-body">
            <p>{sequence}</p>
          </div>
        );
      },
    },
    {
      field: "edit",
      // hide: true,
      headerName: "Edit",
      renderHeader: () => <div className="grid-heading">{"Edit"}</div>,
      width: 150,
      renderCell: (params) => {
        // const id = params.row.edit;

        return (
          <button
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: "#337ab7",
              padding: "8px",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={async () => {
              navigate(`/study-management/visits/edit-questionnaire`, {
                state: {
                  id: params?.row?.id,
                  visitId: id,
                },
              });
            }}
          >
            <FaPencilAlt color="white" size={15} />
          </button>
        );
      },
    },
    {
      field: "delete",
      // hide: true,
      headerName: "Delete",
      renderHeader: () => <div className="grid-heading">{"Delete"}</div>,
      width: 150,
      renderCell: (params) => {
        const id = params.row.id;

        return (
          <button
            onClick={() => {
              setDeleteId(id);
              setShowConfirmModal(true);
            }}
            style={{
              cursor: "pointer",
              textAlign: "center",
              background: "red",
              border: "none",
              borderRadius: "5px",
              padding: "10px 10px",
            }}
          >
            <FaTrashAlt
              color="white"
              style={{
                fontSize: "15px",
              }}
            />
          </button>
        );
      },
    },
  ];

  const fetchVisitById = async () => {
    setLoad(true);
    try {
      const res = await getVisitQuestionnaireListService(id);
      if (res?.status) {
        setQuestionnaireData(res?.data?.result?.visitData?.visitQuestionnaires);
        setPageName(res?.data?.result?.visitData?.name);
        setLoad(false);
      }
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoad(true);
      const res = await removeVisitQuestionnaireService(deleteId);
      if (res.status) {
        setLoad(false);
        fetchVisitById(id);
        setShowConfirmModal(false);
        notifyDelete();
      }
    } catch (err) {
      console.log("Error: ", err.message);
      setLoad(false);
      setShowConfirmModal(false);
      requestFailed(err.message);
    }
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/visits">Visits</Link> |{" "}
            <button
              style={{
                background: "none",
                color: "#337AB7",
                border: "none",
                padding: "0",
                font: "inherit",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/study-management/visits/visit-questionnaire", {
                  state: {
                    id: id,
                  },
                });
              }}
            >
              Visit Questionnaire
            </button>
            -{" "}
            <b style={{ color: "gray" }}>
              {sessionStorage?.getItem("studyName")}
            </b>
          </p>
          <Row style={{ marginTop: "2%", marginBottom: "2%" }}>
            <Col md={6}>
              <div className="study-management-head-start">
                <p className="study-management-heading">
                  {pageName} Questionnaires
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="study-management-head-end">
                <button
                  className="study-management-create-btn-md"
                  onClick={() => {
                    navigate("/study-management/visits/create-questionnaire", {
                      state: {
                        id: id,
                      },
                    });
                  }}
                >
                  Add Questionnaire
                </button>
              </div>
            </Col>
          </Row>

          <Box sx={{ height: 400, width: "100%", display: "flex" }}>
            <ThemeProvider theme={getMuiTheme}>
              <StripedDataGrid
                className="allQuestionnaire-grid"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: GridToolbar }}
                pagination
                rowHeight={38}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </ThemeProvider>
          </Box>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management/visits">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      {showConfirmModal && (
        <DeleteModal
          open={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
          handleDelete={handleDeleteConfirm}
          subject={"Questionnaire"}
        />
      )}
    </>
  );
};

export default VisitQuestionnaires;
