import { studyServer } from "../../../../../services/study_axios";

export const getAllCountriesService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("/app/Country/GetAllCountries")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getAllNewCountriesService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get("app/CountryDragDrop/GetCountryPaging?pageNumber=1&pageSize=1000")
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNewCountryService = (code, name) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Country/AddCountry?Code=${code}&Name=${name}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getCountryByIdService = (id) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`/app/Country/GetCountryById?CountryId=${id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const editCountryService = (item) => {
  const data = {
    id: item?.id,
    shortName: item?.formCountryCode,
    name: item?.name,
    notes: item?.notes,
    lastUpdate: item?.lastUpdate,
    region: item?.region,
    patientDobformat: item?.dob,
    recordPatientInitials: item?.recordPatient,
    useSubjectCode: item?.subjectCode,
    recordPatientGender: item?.recordGender,
    recordPatientDob: item?.recordDob,
    useTwentyFourHourTime: item?.useHour,
    softwareReleaseId: "",
    languages: [],
  };

  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Country/EditCountry`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getCoutryLanguage = (shortName) => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(
        `/app/Country/GetCountryLanguagesBYcountryCode?CountryShortName=${shortName}`
      )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const updateCountryLanguageService = (item, countryLanguages) => {
  const data = {
    id: item?.id,
    shortName: item?.formCountryCode,
    name: item?.name,
    notes: item?.notes,
    lastUpdate: item?.lastUpdate,
    region: item?.region,
    patientDobformat: item?.dob,
    recordPatientInitials: item?.recordPatient,
    useSubjectCode: item?.subjectCode,
    recordPatientGender: item?.recordGender,
    recordPatientDob: item?.recordDob,
    useTwentyFourHourTime: item?.useHour,
    softwareReleaseId: "",
    languages: countryLanguages,
  };
  return new Promise((resolve, reject) => {
    studyServer
      .post(`/app/Country/UpdateCountryLanguage`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
