import { studyServer } from "../../../../services/study_axios";

export const getCalculationDataService = () => {
  return new Promise((resolve, reject) => {
    studyServer
      .get(`app/Calculation/CalculationDataOnLoad`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const addNewCalculationService = (data) => {
  return new Promise((resolve, reject) => {
    studyServer
      .post(`app/Calculation/AddCalculation`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
