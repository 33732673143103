import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrashAlt, FaClone, FaCheck, FaTimes } from "react-icons/fa";
import { Container, Row, Col, Form } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import { Box, TextField } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import SubjectSidebar from "./SubjectSidebar";
import SubjectForm from "./SubjectForm";
import SubjectDataTable from "./SubjectDatatable";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";
import { DataGridPro, gridClasses, GridToolbar } from "@mui/x-data-grid-pro";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import useSubjectAttributesDetails from "../../../../hooks/Api/useSubjectAttributesDetails";


import "../StudySetup.css";
const ODD_OPACITY = 0.2;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubjectTest = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const [load, setLoad] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null); 
  const [formData, setFormData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [pacDisplayNames, setPacDisplayNames] = useState([]);

  const { subjectAttributeQuery } = useSubjectAttributesDetails();
  const { data } = subjectAttributeQuery;


  useEffect(() => {
    if (data?.pac) {
      const displayNames = data.pac.map((pacItem) => pacItem.displayName);
      setPacDisplayNames(displayNames);
    }
  }, [data]);

  const countries =
    data?.countries?.map((country) => ({
      id: country.id,
      name: country.name,
    })) || [];

  useEffect(() => {
    setSelectedOption(selectedCountry ? selectedCountry.name : "");
  }, [selectedCountry]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  const handleListItemClick = (item) => {
    setSelectedOption(item);
    const selectedCountryObj = countries.find((c) => c.name === item);
    if (selectedCountryObj) {
      setSelectedCountry(selectedCountryObj);
    }
  };

  const handleFormSubmit = (data) => {
    setFormData((prevData) => [
      ...prevData,
      { id: prevData.length + 1, ...data },
    ]);
  };

  const handleEdit = (row) => {
    console.log("Edit", row);
  };

  const handleDelete = (row) => {
    setFormData((prevData) => prevData.filter((item) => item.id !== row.id));
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    const selectedCountryObj = countries.find((c) => c.name === selectedValue);
    if (selectedCountryObj) {
      setSelectedCountry(selectedCountryObj);
    }
  };

  const handleAddCountry = (newCountry) => {
    if (!pacDisplayNames.includes(newCountry)) {
      setPacDisplayNames([newCountry, ...pacDisplayNames]);
    }
  };

  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const subjectAttributeInput = { fontSize: 12, width: "275px", height: "5px" };

  const fieldsWidth = {
    width: "300px",
  };

  const heightStyle = {
    height: "40px",
  };

  const textBoxStyles = {
    fontSize: 15,
    width: "210px",
    height: "5px",
  };

  return (
    <>
      {load ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <BeatLoader color="#3661eb" />
          </div>
        </>
      ) : (
        <div className="content-body">
          <p className="study-management-link" style={{ fontWeight: "600" }}>
            <Link to="/study-management">Manage Your Study</Link> |{" "}
            <Link to="/study-management/subject-attributes">
              Subject Attribues
            </Link>
          </p>
          <Box sx={{ marginTop: "2%" }}>
            <Box
              sx={{ width: "100%", borderColor: "divider" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="manageStudyTabsBody"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="manageStudyTabs"
              >
                <Tab label="Subject Attributes" {...a11yProps(0)} />
                <Tab label="Subject Configuration" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box
                component="form"
                sx={{ height: "auto", width: "100%", padding: "4%" }}
                noValidate
                autoComplete="off"
              >
                <Container fluid className="h-100">
                  <Row className="h-100">
                    {/* Sidebar */}
                    <Col xs={12} md={3} className="p-0">
                      <div className="h-100 d-flex flex-column bg-[#3E4CF4] rounded-md">
                        <SubjectSidebar
                          countries={countries}
                          selectedCountry={selectedCountry}
                          onCountryChange={handleCountryChange}
                          onListItemClick={handleListItemClick}
                          onAddCountry={handleAddCountry}
                        />
                      </div>
                    </Col>

                    {/* Main Content */}
                    <Col xs={12} md={9} className="p-3 d-flex flex-column">
                      {/* Dropdown on top left */}
                      <div className="d-flex justify-content-start mb-3">
                        <Form.Select
                          value={selectedOption}
                          onChange={handleDropdownChange}
                          style={{ width: "200px" }}
                          className="border border-gray-300 p-3 rounded-md outline-none select-none"
                        >
                          {pacDisplayNames.map((displayName, index) => (
                            <option
                              key={index}
                              value={displayName}
                              className="select-none"
                            >
                              {displayName}
                            </option>
                          ))}
                        </Form.Select>
                      </div>

                      {/* Form and DataTable */}
                      <div className="flex-grow-1 d-flex flex-column">
                        <SubjectForm onSubmit={handleFormSubmit} />
                        <SubjectDataTable
                          rows={formData}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                          className="mt-4"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="studyManageBody">
                <div className="subjectConfiguration">
                  <div className="subjectVariableHead">
                    <h1>Subject Variable</h1>
                  </div>
                  <Row>
                    <Col md={6}>
                      <div className="subjectVariableBody">
                        <div className="subjectVariableBodyInfo">
                          <p className="smallHeading">
                            Patient Number Include Site Id
                          </p>
                          {/* <p className="subjectDate">Last Updated: 6/14/2022 11:49:22 PM</p> */}
                        </div>
                        <div className="subjectVariableBody2">
                          <p>
                            [bool 0/1] When creating a new subject/patient
                            number this value indicates whether the site id will
                            be included.
                          </p>
                        </div>
                        <div className="subjectVariableInputBody">
                          <TextField
                            inputProps={{
                              style: textBoxStyles,
                            }}
                          />
                          <button className="updateBlueBtn">Update</button>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="subjectVariableBody">
                        <div className="subjectVariableBodyInfo">
                          <p className="smallHeading">Patient Number Length</p>
                          {/* <p className="subjectDate">Last Updated: 6/14/2022 11:49:22 PM</p> */}
                        </div>
                        <div className="subjectVariableBody2">
                          <p>Control the overall format of Subject number</p>
                        </div>
                        <div className="subjectVariableInputBody">
                          <TextField
                            inputProps={{
                              style: textBoxStyles,
                            }}
                          />
                          <button className="updateBlueBtn">Update</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="customDivider"></div>
                  <Row>
                    <Col md={6}>
                      <div className="subjectVariableBody">
                        <div className="subjectVariableBodyInfo">
                          <p className="smallHeading">Patient Number Prefix</p>
                          {/* <p className="subjectDate">Last Updated: 6/14/2022 11:49:22 PM</p> */}
                        </div>
                        <div className="subjectVariableBody2">
                          <p>
                            [string] When creating a new subject/patient number
                            this value will be placed at the beginning of the
                            number. (ie. "S" + SiteNumber + SubjectNumber)
                          </p>
                        </div>
                        <div className="subjectVariableInputBody">
                          <TextField
                            inputProps={{
                              style: textBoxStyles,
                            }}
                          />
                          <button className="updateBlueBtn">Update</button>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="subjectVariableBody">
                        <div className="subjectVariableBodyInfo">
                          <p className="smallHeading">
                            Patient Number Prefix Site Seperator
                          </p>
                          {/* <p className="subjectDate">Last Updated: 6/14/2022 11:49:22 PM</p> */}
                        </div>
                        <div className="subjectVariableBody2">
                          <p>
                            [string] When creating a new subject/patient number
                            this value will be used as a seperator between the
                            prefix and the site id. (ie. Seperator is "-": S +
                            "-" SiteNumber + SubjectNumber)
                          </p>
                        </div>
                        <div className="subjectVariableInputBody">
                          <TextField
                            inputProps={{
                              style: textBoxStyles,
                            }}
                          />
                          <button className="updateBlueBtn">Update</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="customDivider"></div>
                  <Row>
                    <Col md={6}>
                      <div className="subjectVariableBody">
                        <div className="subjectVariableBodyInfo">
                          <p className="smallHeading">
                            Patient Number Site Subject Number Seperator
                          </p>
                          {/* <p className="subjectDate">Last Updated: 6/14/2022 11:49:22 PM</p> */}
                        </div>
                        <div className="subjectVariableBody2">
                          <p>
                            [string] When creating a new subject/patient number
                            this value will be used as a seperator between the
                            prefix and the site id. (ie. Seperator is "-": S +
                            "-" SiteNumber + SubjectNumber)
                          </p>
                        </div>
                        <div className="subjectVariableInputBody">
                          <TextField
                            inputProps={{
                              style: textBoxStyles,
                            }}
                          />
                          <button className="updateBlueBtn">Update</button>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="subjectVariableBody">
                        <div className="subjectVariableBodyInfo">
                          <p className="smallHeading">
                            Patient Number Prefix Site Seperator
                          </p>
                          {/* <p className="subjectDate">Last Updated: 6/14/2022 11:49:22 PM</p> */}
                        </div>
                        <div className="subjectVariableBody2">
                          <p>
                            [string] When creating a new subject/patient number
                            this value will be used as a seperator between the
                            prefix and the site id. (ie. Seperator is "-": S +
                            "-" SiteNumber + SubjectNumber)
                          </p>
                        </div>
                        <div className="subjectVariableInputBody">
                          <TextField
                            inputProps={{
                              style: textBoxStyles,
                            }}
                          />
                          <button className="updateBlueBtn">Update</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="customDivider"></div>
                </div>
              </div>
            </TabPanel>
          </Box>

          <Row style={{ marginTop: "2%" }}>
            <Col md={6}></Col>
            <Col md={6}>
              <Link to="/study-management">
                <div className="study-management-head-end">
                  <button className="study-management-create-btn-lg">
                    Back To Create Study
                  </button>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default SubjectTest;
